<!--
 * @Description:服务商信息列表
 * @Author: ChenXueLin
 * @Date: 2022-03-04 09:28:40
 * @LastEditTime: 2022-07-15 16:02:31
 * @LastEditors: ChenXueLin
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item prop="provinceId">
              <e6-vr-select
                v-model="searchForm.provinceId"
                :data="provinceList"
                placeholder="省"
                title="省"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name'
                }"
                @change="changeProvince"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="cityId">
              <e6-vr-select
                v-model="searchForm.cityId"
                :data="cityNameList"
                placeholder="市"
                title="市"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name'
                }"
                @change="changeCity"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="countyId">
              <e6-vr-select
                v-model="searchForm.countyId"
                :data="districtList"
                placeholder="区"
                title="区"
                clearable
                virtual
                :is-title="true"
                :props="{
                  id: 'code',
                  label: 'name'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="engineerName">
              <el-input
                v-model="searchForm.engineerName"
                placeholder="工程师"
                title="工程师"
              ></el-input>
            </el-form-item>
            <el-form-item prop="engineerAttributeList">
              <e6-vr-select
                v-model="searchForm.engineerAttributeList"
                :data="engineerAttributeData"
                placeholder="工程师属性"
                title="工程师属性"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item prop="workCorpIdList">
              <remote-search
                v-model="searchForm.workCorpIdList"
                :remote="true"
                clearable
                placeholder="客户名称"
                title="客户名称"
                searchValueKey="name"
                multiple
                :props="{
                  id: 'cdcId',
                  label: 'customerName'
                }"
                :queryListAPI="queryCustomer"
              ></remote-search>
            </el-form-item>
            <el-form-item class="search-item--1" prop="bigAreaList">
              <e6-vr-select
                v-model="searchForm.bigAreaList"
                :data="areaList"
                placeholder="所属大区"
                title="所属大区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="areaList">
              <e6-vr-select
                v-model="searchForm.areaList"
                :data="cityList"
                placeholder="所属片区"
                title="所属片区"
                clearable
                multiple
                :props="{
                  id: 'areaId',
                  label: 'areaName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
        <template v-slot:operateAction>
          <i class="e6-icon-add_line" title="新增" @click="handleAdd"></i>
          <i class="e6-icon-clear_line" title="删除" @click="handleDelete"></i>
          <i class="e6-icon-export_fill" title="导出" @click="exportData"></i>
          <i class="e6-icon-Import_fill" title="导入" @click="importXlxs"></i>
        </template>
      </table-title>
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
          class="elTable"
          ref="elTable"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="50"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{
                  scope.$index +
                    1 +
                    (searchForm.pageIndex - 1) * searchForm.pageSize
                }}
              </span>
            </template>
          </el-table-column>
          <!-- 序号 end -->
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="120"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <section class="pagination-wrapper fixed-section" ref="paginationWrapper">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
    </div>
    <add
      :addVisible.sync="addVisible"
      @refreshList="handleSearch"
      :clickRow="clickRow"
      :addType="addType"
    ></add>
    <detail :detailVisible.sync="detailVisible" :clickRow="clickRow"></detail>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { printError } from "@/utils/util";
import add from "./add.vue";
import detail from "./detail.vue";
import { e3Api } from "@/config/api";
const { E3_BL_EWOS = "" } = e3Api;
import { exportData } from "@/utils/download";
import {
  getListAreaAll,
  getProviceList,
  findDownList,
  queryCustomer,
  getAssignRuleList,
  deleteAssignRule,
  exportAssignRule
} from "@/api";
export default {
  name: "dispatchRulesList",
  data() {
    return {
      queryCustomer,
      queryListAPI: getAssignRuleList,
      searchForm: {
        provinceId: "",
        cityId: "",
        countyId: "",
        workCorpIdList: [],
        engineerAttributeList: "",
        engineerName: "",
        bigAreaList: [],
        areaList: [],
        pageIndex: 1,
        pageSize: 20
      },
      total: 0,
      loading: false,
      columnData: [
        {
          fieldName: "bigAreaName",
          display: true,
          fieldLabel: "所属大区",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "areaName",
          display: true,
          fieldLabel: "所属片区",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "provinceName",
          display: true,
          fieldLabel: "省",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "cityName",
          display: true,
          fieldLabel: "市",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "countyName",
          display: true,
          fieldLabel: "区",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "onlinePlatformName",
          display: true,
          fieldLabel: "上线平台",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workTypeName",
          display: true,
          fieldLabel: "工单类型",
          width: 130,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerName",
          display: true,
          fieldLabel: "工程师",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "engineerAttributeName",
          display: true,
          fieldLabel: "工程师属性",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "statusName",
          display: true,
          fieldLabel: "单据状态",
          width: 100,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedUser",
          display: true,
          fieldLabel: "操作人",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "updatedTime",
          display: true,
          fieldLabel: "修改时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: [], // 表格数据
      areaList: [], //大区
      cityList: [], //片区
      provinceList: [],
      cityNameList: [],
      districtList: [],
      engineerAttributeData: [],
      addVisible: false,
      addType: 1,
      clickRow: {},
      logVisible: false,
      selColumnId: [],
      detailVisible: false
    };
  },
  mixins: [listPage, base, listPageReszie],
  components: { add, detail },
  compute: {},
  watch: {},
  created() {
    this.queryData("-65536", "");
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [
          findDownList(["engineerAttribute"]),
          getListAreaAll({ areaType: 1 }),
          getListAreaAll({ areaType: 2 })
        ];
        let [enumRes, areaRes, cityRes] = await Promise.all(promiseList);
        //工程师属性
        this.engineerAttributeData = this.getFreezeResponse(enumRes, {
          path: "data.engineerAttribute"
        });
        //获取大区
        this.areaList = this.getFreezeResponse(areaRes, {
          path: "data"
        });
        //获取片区
        this.cityList = this.getFreezeResponse(cityRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      }
    },
    //获取省市区下拉框数据
    async queryData(type, code) {
      try {
        let res = await getProviceList({ type, code });
        switch (type) {
          case "-65536":
            this.provinceList = res.data;
            break;
          case "-256":
            this.cityNameList = res.data;
            break;
          case "-1":
            this.districtList = res.data;
            break;
          default:
            break;
        }
      } catch (error) {
        printError(error);
      }
    },
    changeProvince() {
      this.queryData("-256", this.searchForm.provinceId);
    },
    changeCity() {
      this.queryData("-1", this.searchForm.cityId);
    },

    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "编辑",
          id: 1
        },
        {
          label: "详情",
          id: 2
        },
        {
          label: "删除",
          id: 3
        }
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.clickRow = row;
      this.addVisible = val.id == 1 ? true : false;
      this.addType = 2;
      this.detailVisible = val.id == 2 ? true : false;
      if (val.id == 3) {
        this.selColumnId = [row.assignRuleId];
        this.handleDelete();
      }
    },
    //新增
    handleAdd() {
      this.addVisible = true;
      this.addType = 1;
    },
    //表格选中数据
    handleSelectionChange(column) {
      this.selColumnId = column.map(item => item.assignRuleId);
    },
    //批量修改
    handleBatchEdit() {
      if (!this.serviceIdList.length) {
        this.$message.warning("请先勾选数据");
        return;
      }
      this.addVisible = true;
      this.addType = 4;
    },
    handleDelete() {
      if (!this.selColumnId.length) {
        this.$message.warning("请选择数据");
        return;
      }
      this.$confirm("是否确认删除？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.deleteReq();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    },
    async deleteReq() {
      try {
        this.loading = true;
        let res = await deleteAssignRule({
          assignRuleIdList: this.selColumnId
        });
        if (res.code == "OK") {
          this.$message.success("删除成功");
          this.selColumnId = [];
          this.queryList();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //  导出
    exportData() {
      exportData(this, exportAssignRule);
    },
    // 导入
    importXlxs() {
      let route = {
        name: "uploadCenter",
        params: {
          refresh: true,
          moduleKey: "assign_rule_import",
          origin: "resourceManagement/dispatchRulesManagement",
          serverName: E3_BL_EWOS,
          maxDataSize: 10
        }
      };
      this.routerPush(route);
    }
  }
};
</script>
<style lang="scss" scoped></style>
