var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{directives:[{name:"dialogDrag",rawName:"v-dialogDrag"},{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"title":_vm.title,"visible":_vm.visible,"width":"600px","before-close":_vm.closeDialog,"close-on-click-modal":false,"append-to-body":"","element-loading-background":_vm.loadingBackground,"custom-class":"add-concat-dialog"},on:{"update:visible":function($event){_vm.visible=$event},"open":_vm.openDialog}},[_c('el-form',{ref:"addForm",attrs:{"label-width":"135px","model":_vm.addForm,"rules":_vm.addFormRules}},[_c('el-form-item',{attrs:{"label":"省:","prop":"provinceId"}},[_c('e6-vr-select',{attrs:{"data":_vm.provinceList,"placeholder":"省","title":"省","clearable":"","virtual":"","is-title":true,"props":{
          id: 'code',
          label: 'name'
        }},on:{"change":_vm.changeProvince},model:{value:(_vm.addForm.provinceId),callback:function ($$v) {_vm.$set(_vm.addForm, "provinceId", $$v)},expression:"addForm.provinceId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"cityId","label":"市:"}},[_c('e6-vr-select',{attrs:{"data":_vm.cityNameList,"placeholder":"市","title":"市","clearable":"","virtual":"","is-title":true,"props":{
          id: 'code',
          label: 'name'
        }},on:{"change":_vm.changeCity},model:{value:(_vm.addForm.cityId),callback:function ($$v) {_vm.$set(_vm.addForm, "cityId", $$v)},expression:"addForm.cityId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"区:","prop":"countyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.districtList,"placeholder":"区","title":"区","clearable":"","virtual":"","is-title":true,"props":{
          id: 'code',
          label: 'name'
        }},on:{"change":_vm.changeCounty},model:{value:(_vm.addForm.countyId),callback:function ($$v) {_vm.$set(_vm.addForm, "countyId", $$v)},expression:"addForm.countyId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"上线平台:","prop":"onlinePlatformList"}},[_c('e6-vr-select',{attrs:{"data":_vm.onlinePlatList,"placeholder":"上线平台","title":"上线平台","clearable":"","multiple":"","props":{
          id: 'codeValue',
          label: 'codeName'
        }},model:{value:(_vm.addForm.onlinePlatformList),callback:function ($$v) {_vm.$set(_vm.addForm, "onlinePlatformList", $$v)},expression:"addForm.onlinePlatformList"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"工单类型:","prop":"workTypeList"}},[_c('e6-vr-select',{attrs:{"data":_vm.taskTypeList,"placeholder":"工单类型","title":"工单类型","multiple":"","clearable":"","props":{
          id: 'codeValue',
          label: 'codeName'
        }},model:{value:(_vm.addForm.workTypeList),callback:function ($$v) {_vm.$set(_vm.addForm, "workTypeList", $$v)},expression:"addForm.workTypeList"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"客户名称:","prop":"workCorpId"}},[_c('remote-search',{attrs:{"remote":true,"clearable":"","placeholder":"客户名称","title":"客户名称","searchValueKey":"name","props":{
          id: 'cdcId',
          label: 'customerName'
        },"queryListAPI":_vm.queryCustomer,"defaultValue":_vm.addType == 1
            ? {}
            : { cdcId: _vm.addForm.workCorpId, customerName: _vm.addForm.corpName },"showInit":_vm.addType == 1 ? true : false},on:{"change":_vm.changeCorp},model:{value:(_vm.addForm.workCorpId),callback:function ($$v) {_vm.$set(_vm.addForm, "workCorpId", $$v)},expression:"addForm.workCorpId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"工程师:","prop":"engineerId"}},[_c('e6-vr-select',{attrs:{"data":_vm.engineerList,"placeholder":"工程师","title":"工程师","props":{
          id: 'employeeId',
          label: 'employeeName'
        },"clearable":""},on:{"change":_vm.changeEngineer},model:{value:(_vm.addForm.engineerId),callback:function ($$v) {_vm.$set(_vm.addForm, "engineerId", $$v)},expression:"addForm.engineerId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"label":"是否启用:","prop":"isenable"}},[_c('el-switch',{attrs:{"active-value":1,"inactive-value":0},model:{value:(_vm.addForm.isenable),callback:function ($$v) {_vm.$set(_vm.addForm, "isenable", $$v)},expression:"addForm.isenable"}})],1)],1),_vm._v(" "),_c('div',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"cancel",on:{"click":_vm.closeDialog}},[_vm._v("取消")]),_vm._v(" "),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.confirm}},[_vm._v("确定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }