var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{attrs:{"prop":"provinceId"}},[_c('e6-vr-select',{attrs:{"data":_vm.provinceList,"placeholder":"省","title":"省","clearable":"","virtual":"","is-title":true,"props":{
                id: 'code',
                label: 'name'
              }},on:{"change":_vm.changeProvince},model:{value:(_vm.searchForm.provinceId),callback:function ($$v) {_vm.$set(_vm.searchForm, "provinceId", $$v)},expression:"searchForm.provinceId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"cityId"}},[_c('e6-vr-select',{attrs:{"data":_vm.cityNameList,"placeholder":"市","title":"市","clearable":"","virtual":"","is-title":true,"props":{
                id: 'code',
                label: 'name'
              }},on:{"change":_vm.changeCity},model:{value:(_vm.searchForm.cityId),callback:function ($$v) {_vm.$set(_vm.searchForm, "cityId", $$v)},expression:"searchForm.cityId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"countyId"}},[_c('e6-vr-select',{attrs:{"data":_vm.districtList,"placeholder":"区","title":"区","clearable":"","virtual":"","is-title":true,"props":{
                id: 'code',
                label: 'name'
              }},model:{value:(_vm.searchForm.countyId),callback:function ($$v) {_vm.$set(_vm.searchForm, "countyId", $$v)},expression:"searchForm.countyId"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"engineerName"}},[_c('el-input',{attrs:{"placeholder":"工程师","title":"工程师"},model:{value:(_vm.searchForm.engineerName),callback:function ($$v) {_vm.$set(_vm.searchForm, "engineerName", $$v)},expression:"searchForm.engineerName"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"engineerAttributeList"}},[_c('e6-vr-select',{attrs:{"data":_vm.engineerAttributeData,"placeholder":"工程师属性","title":"工程师属性","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.engineerAttributeList),callback:function ($$v) {_vm.$set(_vm.searchForm, "engineerAttributeList", $$v)},expression:"searchForm.engineerAttributeList"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"workCorpIdList"}},[_c('remote-search',{attrs:{"remote":true,"clearable":"","placeholder":"客户名称","title":"客户名称","searchValueKey":"name","multiple":"","props":{
                id: 'cdcId',
                label: 'customerName'
              },"queryListAPI":_vm.queryCustomer},model:{value:(_vm.searchForm.workCorpIdList),callback:function ($$v) {_vm.$set(_vm.searchForm, "workCorpIdList", $$v)},expression:"searchForm.workCorpIdList"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"bigAreaList"}},[_c('e6-vr-select',{attrs:{"data":_vm.areaList,"placeholder":"所属大区","title":"所属大区","clearable":"","multiple":"","props":{
                id: 'areaId',
                label: 'areaName'
              }},model:{value:(_vm.searchForm.bigAreaList),callback:function ($$v) {_vm.$set(_vm.searchForm, "bigAreaList", $$v)},expression:"searchForm.bigAreaList"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"areaList"}},[_c('e6-vr-select',{attrs:{"data":_vm.cityList,"placeholder":"所属片区","title":"所属片区","clearable":"","multiple":"","props":{
                id: 'areaId',
                label: 'areaName'
              }},model:{value:(_vm.searchForm.areaList),callback:function ($$v) {_vm.$set(_vm.searchForm, "areaList", $$v)},expression:"searchForm.areaList"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true},{key:"operateAction",fn:function(){return [_c('i',{staticClass:"e6-icon-add_line",attrs:{"title":"新增"},on:{"click":_vm.handleAdd}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-clear_line",attrs:{"title":"删除"},on:{"click":_vm.handleDelete}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-export_fill",attrs:{"title":"导出"},on:{"click":_vm.exportData}}),_vm._v(" "),_c('i',{staticClass:"e6-icon-Import_fill",attrs:{"title":"导入"},on:{"click":_vm.importXlxs}})]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{ref:"elTable",staticClass:"elTable",attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""},on:{"selection-change":_vm.handleSelectionChange}},[_c('el-table-column',{attrs:{"type":"selection","width":"50"}}),_vm._v(" "),_c('el-table-column',{attrs:{"label":"序号","width":"50","fixed":"left","header-align":"center","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v("\n              "+_vm._s(scope.$index +
                  1 +
                  (_vm.searchForm.pageIndex - 1) * _vm.searchForm.pageSize)+"\n            ")])]}}])}),_vm._v(" "),_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"}})}),_vm._v(" "),_c('el-table-column',{attrs:{"fixed":"right","align":"center","label":"操作","width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('e6-td-operate',{attrs:{"data":_vm.getOperateList(scope.row)},on:{"command":function($event){return _vm.handleOperate($event, scope.row)}}})]}}])})],2)],1),_vm._v(" "),_c('section',{ref:"paginationWrapper",staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1),_vm._v(" "),_c('add',{attrs:{"addVisible":_vm.addVisible,"clickRow":_vm.clickRow,"addType":_vm.addType},on:{"update:addVisible":function($event){_vm.addVisible=$event},"update:add-visible":function($event){_vm.addVisible=$event},"refreshList":_vm.handleSearch}}),_vm._v(" "),_c('detail',{attrs:{"detailVisible":_vm.detailVisible,"clickRow":_vm.clickRow},on:{"update:detailVisible":function($event){_vm.detailVisible=$event},"update:detail-visible":function($event){_vm.detailVisible=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }