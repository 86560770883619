<!--
 * @Description: 新建服务商信息
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    :title="title"
    :visible.sync="visible"
    width="600px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    v-loading="loading"
    @open="openDialog"
  >
    <el-form
      ref="addForm"
      label-width="135px"
      :model="addForm"
      :rules="addFormRules"
    >
      <el-form-item label="省:" prop="provinceId">
        <e6-vr-select
          v-model="addForm.provinceId"
          :data="provinceList"
          placeholder="省"
          title="省"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'code',
            label: 'name'
          }"
          @change="changeProvince"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item prop="cityId" label="市:">
        <e6-vr-select
          v-model="addForm.cityId"
          :data="cityNameList"
          placeholder="市"
          title="市"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'code',
            label: 'name'
          }"
          @change="changeCity"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="区:" prop="countyId">
        <e6-vr-select
          v-model="addForm.countyId"
          :data="districtList"
          placeholder="区"
          title="区"
          clearable
          virtual
          :is-title="true"
          :props="{
            id: 'code',
            label: 'name'
          }"
          @change="changeCounty"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="上线平台:" prop="onlinePlatformList">
        <e6-vr-select
          v-model="addForm.onlinePlatformList"
          :data="onlinePlatList"
          placeholder="上线平台"
          title="上线平台"
          clearable
          multiple
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="工单类型:" prop="workTypeList">
        <e6-vr-select
          v-model="addForm.workTypeList"
          :data="taskTypeList"
          placeholder="工单类型"
          title="工单类型"
          multiple
          clearable
          :props="{
            id: 'codeValue',
            label: 'codeName'
          }"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="客户名称:" prop="workCorpId">
        <remote-search
          v-model="addForm.workCorpId"
          :remote="true"
          clearable
          placeholder="客户名称"
          title="客户名称"
          searchValueKey="name"
          :props="{
            id: 'cdcId',
            label: 'customerName'
          }"
          :queryListAPI="queryCustomer"
          :defaultValue="
            addType == 1
              ? {}
              : { cdcId: addForm.workCorpId, customerName: addForm.corpName }
          "
          :showInit="addType == 1 ? true : false"
          @change="changeCorp"
        ></remote-search>
      </el-form-item>
      <el-form-item label="工程师:" prop="engineerId">
        <e6-vr-select
          v-model="addForm.engineerId"
          :data="engineerList"
          placeholder="工程师"
          title="工程师"
          :props="{
            id: 'employeeId',
            label: 'employeeName'
          }"
          clearable
          @change="changeEngineer"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="是否启用:" prop="isenable">
        <el-switch
          v-model="addForm.isenable"
          :active-value="1"
          :inactive-value="0"
        >
        </el-switch>
      </el-form-item>
    </el-form>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import {
  findDownList,
  getEngineer,
  getProviceList,
  createAssignRule,
  queryMaterialList,
  getAssignRuleDetail,
  queryCustomer
} from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "add",
  components: {},
  data() {
    return {
      queryMaterialList,
      queryCustomer,
      classifyList: [],
      loading: false,
      addForm: {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        countyId: "",
        countyName: "",
        workCorpId: "",
        engineerId: "",
        corpName: "",
        isenable: 1,
        workTypeList: [],
        onlinePlatformList: []
      },
      onlinePlatList: [
        {
          codeName: "易流云",
          codeValue: 1
        },
        {
          codeName: "G7S",
          codeValue: 2
        }
      ],
      taskTypeList: [], //工单类型
      provinceList: [],
      cityNameList: [],
      districtList: [],
      engineerList: [],
      addFormRules: {
        provinceId: [
          {
            required: true,
            message: "请选择省",
            trigger: ["blur", "change"]
          }
        ],
        cityId: [
          {
            required: true,
            message: "请选择市",
            trigger: ["blur", "change"]
          }
        ],
        countyId: [
          {
            required: true,
            message: "请选择区",
            trigger: ["blur", "change"]
          }
        ],
        onlinePlatformList: [
          {
            required: true,
            message: "请选择上线平台",
            trigger: ["blur", "change"]
          }
        ],
        engineerId: [
          {
            required: true,
            message: "请选择工程师",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: {
    addVisible: {
      type: Boolean,
      default: false
    },
    //1新增2编辑3详情
    addType: {
      type: [Number, String],
      default: 1
    },
    clickRow: {
      type: Object,
      default: () => {}
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.addVisible;
      },
      set(val) {
        this.$emit("update:addVisible", val);
      }
    },
    title() {
      let res;
      switch (this.addType) {
        case 1:
          res = "新建";
          break;
        case 2:
          res = "编辑";
          break;
        default:
          res = "";
          break;
      }

      return res;
    }
  },
  watch: {
    "addForm.provinceId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryData("-256", this.addForm.provinceId);
        }
      }
    },
    "addForm.cityId": {
      immediate: true,
      handler(val) {
        if (val) {
          this.queryData("-1", this.addForm.cityId);
        }
      }
    }
  },
  created() {
    this.initData();
    this.queryData("-65536", "");
  },
  methods: {
    async initData() {
      try {
        this.loading = true;
        let promiseList = [findDownList(["taskSecondClass"]), getEngineer({})];
        let [taskTypeRes, engineerRes] = await Promise.all(promiseList);

        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        this.engineerList = this.getFreezeResponse(engineerRes, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    async getDetail() {
      try {
        let res = await getAssignRuleDetail({ id: this.clickRow.assignRuleId });
        let arr = res.data.workTypeList;
        let workTypeList = [];
        arr.map(item => {
          workTypeList.push(item.toString());
        });
        this.addForm = { ...res.data, workTypeList };
      } catch (error) {
        printError(error);
      }
    },
    //获取省市区下拉框数据
    async queryData(type, code) {
      try {
        let res = await getProviceList({ type, code });
        switch (type) {
          case "-65536":
            this.provinceList = res.data;
            break;
          case "-256":
            this.cityNameList = res.data;
            break;
          case "-1":
            this.districtList = res.data;
            break;
          default:
            break;
        }
      } catch (error) {
        printError(error);
      }
    },
    changeProvince(val, node) {
      this.addForm.provinceName = node.name;
    },
    changeCity(val, node) {
      this.addForm.cityName = node.name;
    },
    changeCounty(val, node) {
      this.addForm.countyName = node.name;
    },
    changeEngineer(val, node) {
      this.addForm.engineerName = node.employeeName;
    },
    //打开
    openDialog() {
      if (this.addType != 1) {
        this.getDetail();
      }
      this.$nextTick(() => {
        this.$refs.addForm.clearValidate();
      });
    },
    changeCorp(val, node) {
      this.addForm.corpName = node.customerName;
    },
    //点击确定
    confirm() {
      this.$refs.addForm.validate(valid => {
        if (valid) {
          this.createAssignRuleReq();
        }
      });
    },
    async createAssignRuleReq() {
      try {
        this.loading = true;
        let res = await createAssignRule(this.addForm);
        if (res.code == "OK") {
          this.$message.success("新增成功");
          this.$emit("refreshList");
          this.closeDialog();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },

    closeDialog() {
      this.visible = false;
      this.addForm = {
        provinceId: "",
        provinceName: "",
        cityId: "",
        cityName: "",
        countyId: "",
        countyName: "",
        workCorpId: "",
        engineerId: "",
        corpName: "",
        isenable: 1,
        workTypeList: [],
        onlinePlatformList: []
      };
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 10px;
    box-sizing: border-box;
    overflow-y: auto;
    max-height: 600px;
    .el-input {
      width: 250px;
    }
  }
}
</style>
